import React, { FunctionComponent, ComponentClass } from 'react'
import Modal, { ModalFuncProps } from 'antd/es/modal'
import ReactDOM from 'react-dom'
import { Button } from 'antd'

interface DialogProps extends ModalFuncProps {
  componentProps?: any
  content?: string | FunctionComponent<any> | ComponentClass<any, any> | any
  cancelBtn?: boolean
}

function createDialog(config: DialogProps) {
  const { onOk, onCancel, content, componentProps = {}, cancelBtn } = config
  const props = { ...config, visible: true }

  const div = document.createElement('div')
  document.body.appendChild(div)

  // 判断是否为antdForm包裹的组件
  let isAntdFromWrapped = false
  let contentNode: any
  let ref: any
  let handleSubmit: any

  if (content) {
    if (content.displayName.includes('Form')) {
      isAntdFromWrapped = true
    }

    if (isAntdFromWrapped) {
      contentNode = React.createElement(content, {
        ...componentProps,
        wrappedComponentRef: (wrappedComponent: any) => (ref = wrappedComponent)
      })
    } else {
      ref = React.createRef()
      contentNode = React.createElement(content, {
        ...componentProps,
        ref
      })
    }
  }

  if (onOk) {
    if (isAntdFromWrapped) {
      handleSubmit = (...args: any[]) => onOk(ref, ...args)
    } else {
      handleSubmit = (...args: any[]) => onOk(ref.current || {}, ...args)
    }
  }

  const renderFooter = () => {
    return (
      <>
        {cancelBtn && (
          <Button key="back" onClick={onCancel}>
            取消
          </Button>
        )}
        <Button key="submit" type="primary" onClick={handleSubmit}>
          提交
        </Button>
      </>
    )
  }

  function render() {
    const modalProps = {
      ...props,
      footer: renderFooter(),
      onCancel: onCancel || destroy
    }
    ReactDOM.render(<Modal {...modalProps}>{contentNode}</Modal>, div)
  }

  const destroy = () => ReactDOM.unmountComponentAtNode(div)

  render()

  return {
    destroy
  }
}

export default createDialog
