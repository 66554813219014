/**
 * http 拦截器
 * @description  axios拦截器
 * @module core/request
 * @author k.
 */

import { message } from 'antd'
import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { getToken } from '../auth/token.service'
import { deAuthorize, reAuth } from '../auth/auth.service'

export function responseErrorHandler(error: any) {
  const response = error.response

  if (response) {
    const { data, status } = response
    if (status) {
      let errorText: string = ''
      switch (status) {
        case 401:
          reAuth()
          break
        case 500:
          errorText = '服务器异常'
          break
        default:
          errorText = data.message
      }
      if (errorText) {
        message.error(errorText)
      }
    } else {
      message.error('您的网络发生异常，无法连接服务器')
    }
  } else {
    // Something happened in setting up the request that triggered an Error
    console.log('Error', error.message)
  }
  return Promise.reject(error)
}

export function responseSuccessHandler(response: AxiosResponse) {
  if (response.data) {
    if (response.data.code === 'server.success') {
      return response.data
    } else {
      if (response.data.code === 'token.notfound') {
        //退出登录
        deAuthorize()
      } else {
        message.error(response.data.message)
      }
      return Promise.reject(response)
    }
  } else {
    return response
  }
}

export function requestInterceptor(config: AxiosRequestConfig) {
  const token = getToken()
  if (token) {
    config.headers.token = token
  }
  return config
}

export function requestRejectInterceptor(error: any) {
  console.error(error)
}
