import React from 'react'
import './UserLayout.css'
import { Route, RouteComponentProps } from 'react-router-dom'
import login from '../pages/user/login'

const UserLayout: React.FC<RouteComponentProps> = props => {
  const { match } = props
  return (
    <div className="container">
      <div className="content">
        <div className="top">
          <div className="header" />
          <h1 className="layout_title">蓝光透镜后台</h1>
        </div>
        <Route path={`${match.url}/login`} component={login} />
      </div>
    </div>
  )
}

export default UserLayout
