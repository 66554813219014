import React from 'react'
import { Router, Route, Redirect, Switch } from 'react-router-dom'
import { history } from 'core/router'
import SecurityLayout from 'layouts/SecurityLayout'
import UserLayout from 'layouts/UserLayout'
import BasicLayout from 'layouts/BasicLayout'
import './App.css'

const App: React.FC = () => {
  return (
    <Router history={history}>
      <Switch>
        <SecurityLayout path="/app" component={BasicLayout} />
        <Route path="/passport" component={UserLayout} />
        <Redirect to="/app" />
      </Switch>
    </Router>
  )
}

export default App
