import React, { useState } from 'react'
import { Button, Modal, message } from 'antd'
import { WrappedFormUtils } from 'antd/es/form/Form'

interface FormModalType {
  title: string
  type?: 'a' | 'button'
  onCancel?: () => void
  width?: number
  onSubmit?: (fields: any) => void
  defaultValues?: Object | Function
  noBtn?: false | true
}

function HocForm(FormComponent: any): React.FC<FormModalType> {
  return function(props) {
    const {
      children,
      title,
      onCancel,
      onSubmit,
      defaultValues,
      type = 'button',
      noBtn = false,
      width = 700
    } = props
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [visible, setVisible] = useState(false)
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [defaultFormValue, setDefaultFormValue] = useState({})

    let form: WrappedFormUtils

    const showModal = async () => {
      if (defaultValues) {
        if (typeof defaultValues === 'function') {
          const values = await defaultValues()
          setDefaultFormValue(values)
        } else {
          setDefaultFormValue(defaultValues)
        }
        setVisible(true)
      } else {
        setVisible(true)
      }
    }

    const hideModal = () => setVisible(false)

    const handleCancel = () => {
      if (onCancel) {
        onCancel()
      } else {
        hideModal()
      }
    }

    const handleSubmit = () => {
      if (onSubmit) {
        form.validateFields(async (err, values) => {
          if (!err) {
            await onSubmit(values)
            hideModal()
            message.success('操作成功')
          }
        })
      } else {
        hideModal()
      }
    }

    const asyncForm = (FormComponentInstance: any) => {
      if (FormComponentInstance) {
        form = FormComponentInstance.props.form
      }
    }
    const Footer = () => {
      if (noBtn) {
        return null
      } else {
        return [
          <div>
            <Button key="submit" type="primary" onClick={handleSubmit}>
              确定
            </Button>
            <Button onClick={handleCancel}>取消</Button>
          </div>
        ]
      }
    }
    return (
      <>
        <Button
          type={type === 'button' ? 'primary' : 'link'}
          onClick={showModal}
        >
          {children}
        </Button>
        {visible && (
          <Modal
            visible={visible}
            title={title}
            width={width}
            footer={Footer()}
            onCancel={handleCancel}
          >
            <FormComponent
              wrappedComponentRef={(form: any) => asyncForm(form)}
              defaultValues={defaultFormValue}
            />
          </Modal>
        )}
      </>
    )
  }
}

export default HocForm
