/**
 * token服务
 * @author k.
 */

import { TokenProps } from '../../pages/user/login/login.service'

const TOKEN = 'TOKEN'
const PERMISSION = 'PERMISSION'
const USER = 'USER'
const REAUTH = 'REAUTH'

export function setReAuthStatus(status: '1' | '0') {
  localStorage.setItem(REAUTH, status)
}

export function getReAuthStatus() {
  const status = localStorage.getItem(REAUTH)
  return status === '1'
}

export function setUser(user: any) {
  localStorage.setItem(USER, JSON.stringify(user))
}

export function getUser() {
  const user = localStorage.getItem(USER)
  if (user) {
    return JSON.parse(user)
  }
  return {}
}

function removeUser() {
  localStorage.removeItem(USER)
}

function getToken() {
  const token = getOriginalToken()
  return token ? token : undefined
}

function getOriginalToken() {
  const token = localStorage.getItem(TOKEN)
  if (token) {
    return JSON.parse(token).accessToken
  }
  return undefined
}

function setToken(accessToken: string, expires: number) {
  const currentTime = new Date().getTime()
  const expiresDate = new Date(currentTime + expires * 1000)

  // expires 单位*天
  localStorage.setItem(
    TOKEN,
    JSON.stringify({ accessToken, expires: expiresDate })
  )
}

function removeToken() {
  localStorage.removeItem(TOKEN)
}

function getPermissionConfig(key: string) {
  const permissionConfigString = localStorage.getItem(PERMISSION)
  if (permissionConfigString) {
    const permissionConfig = JSON.parse(permissionConfigString)
    return permissionConfig[key]
  }
  return undefined
}

function setPermissionConfig(permission: any) {
  localStorage.setItem(PERMISSION, JSON.stringify(permission))
}

function removePermissionConfig() {
  localStorage.removeItem(PERMISSION)
}

function setConfig(authConfig: TokenProps) {
  const { accessToken, expire, resource_filter, rule_list } = authConfig
  setToken(accessToken, expire)
  setPermissionConfig({ resource_filter, rule_list })
}

function removeConfig() {
  removeToken()
  removePermissionConfig()
  removeUser()
  setReAuthStatus('0')
}

export {
  setConfig,
  removeConfig,
  getToken,
  getPermissionConfig,
  getOriginalToken,
  removeToken
}
