/**
 * layout 工具类
 * @author k.
 */

import { filter, map, pick, difference, compact, flatMap } from 'lodash'
import { Route, Routes } from 'core/router/factory'

interface Menu {
  title?: string
  icon?: string
  path: string
  permissions?: string[]
  children?: Menu[]
}

/**
 * ----------------------------------------------------------
 * 获取菜单
 */

function getMenus(routes: Routes) {
  const usableRoutes = filter(routes, route => !route.exclude)
  return map(usableRoutes, route => {
    const { routes: children, meta, path } = route
    let menu = pick(meta, ['title', 'icon', 'permissions'])
    menu.path = path
    if (children) {
      menu.children = getMenus(children) as Menu[]
    }
    return menu
  })
}

function hasPermission(guardPermissions: string[], superPermissions: string[]) {
  return difference(guardPermissions, superPermissions).length === 0
}

// FIXME
//
function permissionMenusFilter(permissions: string[]) {
  function authPermission(menu: Menu) {
    let children = menu.children
    if (children) {
      const authChildren = filter(children, authPermission) as Menu[]
      if (!authChildren.length) {
        return null
      } else {
        menu.children = authChildren
        return menu
      }
    } else {
      if (hasPermission(menu.permissions!, permissions)) {
        return menu
      } else {
        return null
      }
    }
  }
  return (menu: Menu) => authPermission(menu)
}

function getPermissionMenus(routes: Routes, permissions: string[]) {
  const pickedMenus = getMenus(routes)
  // @ts-ignore
  return compact<Menu>(
    // @ts-ignore
    map<Menu>(pickedMenus, permissionMenusFilter(permissions))
  )
}

/**
 * ----------------------------------------------------------
 *  获取路由
 */

function pickRouteConfig(route: any) {
  return pick(route, ['path', 'component'])
}

function flatRoutes(route: Route) {
  const subRoutes = route.routes
  let pickRoute
  if (subRoutes) {
    pickRoute = map(subRoutes, subRoute => {
      return pickRouteConfig(subRoute)
    })
  } else {
    pickRoute = pickRouteConfig(route)
  }
  return pickRoute
}

const getRoutes = (routes: Route[]) => flatMap(routes, flatRoutes)

export { getPermissionMenus, getRoutes }
