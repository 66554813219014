import React, { Component } from 'react'
import { Form, Input, Icon } from 'antd'
import { FormComponentProps } from 'antd/es/form'
import { getUser, setUser } from './token.service'
import { accountLogin } from '../../pages/user/login/login.service'
import { authorize } from './auth.service'

const FormItem = Form.Item

class LoginForm extends Component<FormComponentProps> {
  login() {
    const { validateFields } = this.props.form
    return new Promise((resolve, reject) => {
      validateFields(async (err, values) => {
        if (!err) {
          const loginPayload = {
            username: values.username,
            password: values.password
          }
          const res = (await accountLogin(loginPayload)) as any
          setUser({
            username: loginPayload.username,
            userInfo: res.data.userInfo
          })
          // @ts-ignore
          authorize(res.data)
          resolve()
        }
      })
    })
  }

  render() {
    const { username } = getUser()
    const { getFieldDecorator } = this.props.form
    return (
      <Form>
        <FormItem>
          {getFieldDecorator('username', { initialValue: username })(
            <Input
              disabled
              prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
            />
          )}
        </FormItem>

        <FormItem>
          {getFieldDecorator('password', {
            rules: [{ required: true, message: '请输入密码' }]
          })(
            <Input
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="密码"
              type="password"
            />
          )}
        </FormItem>
      </Form>
    )
  }
}

export class A extends React.Component {
  c() {}

  render() {
    return <div>123123</div>
  }
}

export default Form.create()(LoginForm)
