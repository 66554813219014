import {
  getReAuthStatus,
  removeConfig,
  setConfig,
  setReAuthStatus
} from './token.service'
import { TokenProps } from 'pages/user/login/login.service'
import { history } from 'core/router'
import Dialog from 'components/Dialog'
import LoginDialog from './LoginDialog'

export function authorize(token: TokenProps) {
  setConfig(token)
}

export function deAuthorize() {
  removeConfig()
  history.replace('/passport/login')
}

export function reAuth() {
  if (!getReAuthStatus()) {
    setReAuthStatus('1')
    const dialog = Dialog.create({
      title: '会话过期，请重新登录!',
      content: LoginDialog,
      onOk: async instance => {
        await instance.login()
        dialog.destroy()
        setReAuthStatus('0')
      }
    })
  }
}
