import request from 'core/http/request'

export async function fetch(params: any) {
  return request.get('/unusualResource/listPage', { params })
}

export async function setIntervene(params: any) {
  return request.post('/unusualResource/intervene', params)
}

export async function setIgnore(params: any) {
  return request.post('/unusualResource/ignore', params)
}

export async function getDetail(params: any) {
  return request.get('/unusualResource/detail', { params })
}
