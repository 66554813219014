import { IResponse } from 'core/http/response.interface'
import authRequest from 'core/http/instance/auth'

export interface LoginParamsType {
  username: string
  password: string
}

export interface TokenProps {
  expire: number
  resource_filter: { [key: string]: string[] }
  rule_list: string[]
  accessToken: string
}

type TokenResponse = IResponse<TokenProps>

export async function accountLogin(params: LoginParamsType) {
  return authRequest.post<TokenResponse>('/adminUser/login', params)
}
