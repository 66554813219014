import React, { ComponentType } from 'react'
import { getToken } from 'core/auth/token.service'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import qs from 'querystring'

const loginRoute = '/passport/login'

interface SecurityLayoutProps extends RouteProps {
  component: ComponentType<any>
}

const SecurityLayout: React.FC<SecurityLayoutProps> = ({
  component: Component,
  ...rest
}) => {
  const token = getToken()
  const { pathname } = window.location
  const redirectUrl = loginRoute + '?' + qs.stringify({ redirect: pathname })
  return (
    <Route
      {...rest}
      render={
        props => <Component {...props} />
        // token ? <Component {...props} /> : <Redirect to={redirectUrl} />
      }
    />
  )
}

export default SecurityLayout
