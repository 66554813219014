export const statusPipe = (
  status: number,
  args: 'type' | 'valid' | 'visible' | 'usage' | 'device' | 'user' = 'valid'
) => {
  switch (args) {
    case 'type':
      return status == 1
        ? '异常资源同步中'
        : status == 2
        ? '异常资源同步失败'
        : status == 3
        ? '异常资源忽略'
        : status == 4
        ? '异常资源已同步'
        : status == 4
        ? '状态回填成功'
        : status == 5
        ? '异常资源数据不完整'
        : status == 6
        ? '资源文件已迁移'
        : status == 7
        ? '数据不处理'
        : status == 10
        ? '数据丢弃'
        : status == 66
        ? '异常处理完成'
        : '资源同步异常'
    default:
      return status
  }
}
