import { Modal } from 'antd'
import createDialog from './service'

class Dialog extends Modal {
  static create: typeof createDialog
}

Dialog.create = createDialog

export default Dialog
