import React, { Component } from 'react'
import { PageHeader, Card } from 'antd'
import { FormComponentProps } from 'antd/lib/form'

interface PageComponentProps extends FormComponentProps {
  [key: string]: any
}

interface PageComponentState {
  formValues?: { [key: string]: any }

  [key: string]: any
}

const PageLayout: React.FC<{ title: string }> = props => {
  return (
    <>
      <PageHeader title={props.title} className="my_content_title" />
      <Card>{props.children}</Card>
    </>
  )
}

export class PageComponent<T = {}> extends Component<
  PageComponentProps,
  T & PageComponentState
> {}

export default PageLayout
