import React from 'react'
import { Icon, Layout, Menu } from 'antd'
import { Link } from 'react-router-dom'
import { getPermissionConfig } from '../../core/auth/token.service'
import router from 'router'
import { getPermissionMenus } from './utils'

const { Sider } = Layout
const { SubMenu } = Menu

interface BasicSiderProps {
  collapsed: boolean
}

const BasicSider: React.FC<BasicSiderProps> = props => {
  const menus = getPermissionMenus(
    router.routes,
    getPermissionConfig('rule_list')
  )
  return (
    <Sider theme="light" trigger={null} collapsible collapsed={props.collapsed}>
      <div className="basic-layout__logo">
        {props.collapsed ? '蓝光透镜' : '蓝光透镜后台'}
      </div>
      <Menu theme="light" mode="inline" defaultSelectedKeys={['1']}>
        {menus.map(route => {
          if (!route.children) {
            return (
              <Menu.Item key={route.title}>
                <Link to={route.path}>
                  <Icon type={route.icon} />
                  <span>{route.title}</span>
                </Link>
              </Menu.Item>
            )
          } else {
            return (
              <SubMenu
                key={route.title}
                title={
                  <span>
                    <Icon type={route.icon} />
                    <span>{route.title}</span>
                  </span>
                }
              >
                {route.children.map(r => {
                  return (
                    <Menu.Item key={r.title}>
                      <Link to={r.path}>{r.title}</Link>
                    </Menu.Item>
                  )
                })}
              </SubMenu>
            )
          }
        })}
      </Menu>
    </Sider>
  )
}

export default BasicSider
