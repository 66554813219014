import NotFoundPage from 'pages/401'
import Welcome from 'pages/Welcome'
import RouterFactory, { Routes } from 'core/router/factory'
import Device from './pages/luzhi-gl/device'
const routes: Routes = [
  {
    path: '/abnormal_resources',
    meta: {
      title: '异常资源',
      icon: 'exclamation-circle'
    },
    component: Device
  },
  {
    path: '/welcome',
    component: Welcome,
    exclude: true
  },
  {
    path: '/*',
    component: NotFoundPage,
    exclude: true
  }
]

export default new RouterFactory(routes)
