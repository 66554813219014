/**
 * 异步Table
 * @module component/AsyncTable
 * @author k.
 */

import React, { useEffect, useState } from 'react'
import { Table } from 'antd'
import { PaginationProps } from 'antd/es/pagination'
import { TableProps } from 'antd/es/table'

interface AsyncTableProps extends TableProps<any> {
  query: any
  service?: any
  columns: any
  page_size?: number
}

const AsyncTable: React.FC<AsyncTableProps> = ({
  query,
  columns,
  service,
  page_size,
  ...rest
}) => {
  const [loading, setLoading] = useState(false)
  const [tableData, setTableData] = useState([])

  const [total, setTotal] = useState(0)
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(page_size)

  const pagination: PaginationProps = {
    total,
    pageSize,
    current: page,
    onChange: page => fetchData(page)
  }

  async function fetchData(page?: number) {
    setLoading(true)
    const defaultPage = page || 1
    const defaultPageSize = pageSize || 20
    setPage(defaultPage)
    setPageSize(defaultPageSize)
    const defaultQuery = { pageSize: defaultPageSize, pageNumber: defaultPage }

    const { savePage, ...omitQuery } = query
    const requestQuery = { ...omitQuery, ...defaultQuery }
    let res = { data: [], pageInfo: { total: 0 } }
    if (service) {
      res = await service(requestQuery)
    }
    setTableData(res.data)
    setTotal(+res?.pageInfo?.total)
    setLoading(false)
  }

  // 相当于 componentDidMount 和 componentDidUpdate
  useEffect(
    () => {
      if (query.savePage) {
        fetchData(page)
      } else {
        fetchData()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [query]
  )

  return (
    <Table
      {...rest}
      loading={loading}
      pagination={pagination}
      dataSource={tableData}
      columns={columns}
    />
  )
}

export default AsyncTable
