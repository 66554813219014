import React, { Component } from 'react'
import { Form } from 'antd'
import { FormComponentProps } from 'antd/es/form'
import { statusPipe } from '../../../../uitls/utils'
import { getDetail } from '../service'
interface DetailModalProps extends FormComponentProps {
  defaultValues: any
}
class DetailModal extends Component<DetailModalProps> {
  state = {
    detailData: {} as any
  }
  componentDidMount() {
    const { defaultValues } = this.props
    getDetail({ unusualId: defaultValues.unusualId }).then(res => {
      this.setState({
        detailData: res.data
      })
    })
  }
  render() {
    const { detailData } = this.state
    return (
      <Form>
        <p>资源ID: {detailData.resourceId}</p>
        <p>更新时间: {detailData.updateTime}</p>
        <p>状态: {statusPipe(detailData.type, 'type')}</p>
        <p>异常备注: {detailData.remark}</p>
        <p>MP4资源: {detailData.resourceUrl}</p>
        <p>zip资源: {detailData.zipUrl}</p>
      </Form>
    )
  }
}
export default Form.create()(DetailModal)
