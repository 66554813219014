import React, { Component } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { Icon, Layout, Avatar, Button } from 'antd'
import BasicSider from './BasicSider'
import router from '../../router'
import { getRoutes } from './utils'
import './style.css'
import { getUser } from 'core/auth/token.service'
import { deAuthorize } from '../../core/auth/auth.service'

const { Header, Content } = Layout
class BasicLayout extends Component<{ match: any }> {
  state = {
    collapsed: false,
    timerID: setInterval(() => {}, 5000),
    numObj: {} as any
  }
  logout = () => {
    clearInterval(this.state.timerID)
    deAuthorize()
  }
  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed
    })
  }
  year = () => {
    const now = new Date()
    return now.getFullYear()
  }

  username = () => {
    const user = getUser()
    return user.username || 'null'
  }

  render() {
    const routers = getRoutes(router.routes)
    const username = this.username()
    return (
      <Layout>
        <BasicSider collapsed={this.state.collapsed} />
        <Layout>
          <Header
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Icon
                className="basic-layout__trigger"
                style={{ color: '#fff' }}
                type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'}
                onClick={this.toggle}
              />
            </div>
            <div className="basic-layout__header-right">
              <div className="basic-layout__header-right-avatar">
                <Avatar size="small">{username[0]?.toUpperCase()}</Avatar>
                <strong className="basic-layout__header-username">
                  {username}
                </strong>
                <Button
                  onClick={() => this.logout()}
                  style={{ marginLeft: '10px' }}
                >
                  退出
                </Button>
              </div>
            </div>
          </Header>
          <Content className="basic-layout__content">
            <Switch>
              {routers.map((route, index) => (
                <Route
                  key={index}
                  path={route.path}
                  component={route.component}
                />
              ))}

              <Redirect to="/app/abnormal_resources" />
            </Switch>
          </Content>
          <div style={{ textAlign: 'center', padding: '24px 50px' }}>
            @{this.year()}
          </div>
        </Layout>
      </Layout>
    )
  }
}

export default BasicLayout
