import { ColumnProps } from 'antd/es/table'
import AsyncTable from 'components/AsyncTable'
import DetailModal from './components/DetailModal'
import HocForm from 'components/HocForm'
import React from 'react'
import { omit } from 'lodash'
import { fetch, setIntervene, setIgnore } from './service'
import {
  Button,
  Card,
  Divider,
  Form,
  Input,
  PageHeader,
  Popconfirm,
  Select,
  Tag
} from 'antd'

import { PageComponent } from 'components/PageLayout'
import './index.css'
import RangeDatePicker from '../../../components/RangeDatePicker/RangeDatePicker'
import { resolveRangeDate } from '../../../components/RangeDatePicker'
import { statusPipe } from '../../../uitls/utils'
const DetailModalButton = HocForm(DetailModal)

const Option = Select.Option
class Device extends PageComponent {
  state = {
    formValues: {}
  }
  // 异常资源人工干预
  setIntervene = (unusualId: string) => {
    return setIntervene({
      unusualId: unusualId
    }).then(() => {
      this.handleSearch()
    })
  }
  //异常资源忽略
  setIgnore = (unusualId: string) => {
    return setIgnore({
      unusualId: unusualId
    }).then(() => {
      this.handleSearch()
    })
  }
  handleSearch = () => {
    const { getFieldsValue } = this.props.form
    const params = getFieldsValue()
    const [startCreateTime, endCreateTime] = resolveRangeDate(params.rangeDate)
    let query: any = Object.assign({}, params, {
      startCreateTime,
      endCreateTime
    })
    query = omit(query, 'rangeDate')
    this.setState({
      formValues: query
    })
  }
  columns: ColumnProps<any>[] = [
    {
      title: '资源ID',
      dataIndex: 'resourceId',
      key: 'resourceId'
    },
    {
      title: '更新时间',
      dataIndex: 'updateTime',
      key: 'updateTime'
    },
    {
      title: '状态',
      dataIndex: 'status',
      key: 'updateTime',
      render(text, record) {
        return (
          <Tag color={record.type ? 'green' : 'red'}>
            {statusPipe(record.type, 'type')}
          </Tag>
        )
      }
    },
    {
      title: '异常备注',
      dataIndex: 'remark',
      key: 'remark'
    },
    {
      title: '操作',
      render: (text, record) => (
        <span>
          <Popconfirm
            title={'是否人工干预'}
            onConfirm={() => {
              this.setIntervene(record.unusualId)
            }}
          >
            <Button type="link">人工干预</Button>
          </Popconfirm>
          <Divider type="vertical" />
          <Popconfirm
            title={'是否忽略'}
            onConfirm={() => {
              this.setIgnore(record.unusualId)
            }}
          >
            <Button type="link">忽略</Button>
          </Popconfirm>
          <Divider type="vertical" />
          <DetailModalButton
            title="详情"
            onSubmit={() => {}}
            defaultValues={{ unusualId: record.unusualId }}
            type="a"
          >
            详情
          </DetailModalButton>
        </span>
      )
    }
  ]
  render() {
    const { getFieldDecorator } = this.props.form
    const { formValues } = this.state
    return (
      <>
        <PageHeader title="异常资源" className="my_content_title" />
        <Card>
          {/*搜索表单*/}
          <Form layout="inline" className="margin_bottom_24">
            <Form.Item label="资源名称">
              {getFieldDecorator('name')(<Input />)}
            </Form.Item>
            <Form.Item label="资源ID">
              {getFieldDecorator('resourceId')(<Input />)}
            </Form.Item>
            <Form.Item label="状态">
              {getFieldDecorator('type')(
                <Select
                  placeholder="请选择状态"
                  allowClear={true}
                  style={{ width: '200px' }}
                >
                  <Option key={0}>资源同步异常</Option>
                  <Option key={1}>异常资源同步中</Option>
                  <Option key={2}>异常资源同步失败</Option>
                  <Option key={3}>异常资源忽略</Option>
                  <Option key={4}>异常资源已同步</Option>
                  <Option key={5}>状态回填成功</Option>
                  <Option key={6}>异常资源数据不完整</Option>
                  <Option key={7}>资源文件已迁移</Option>
                  <Option key={10}>数据不处理</Option>
                  <Option key={66}>数据丢弃</Option>
                </Select>
              )}
            </Form.Item>
            <Form.Item label="时间范围">
              {getFieldDecorator('rangeDate')(<RangeDatePicker />)}
            </Form.Item>
            <Form.Item>
              <Button type="primary" onClick={() => this.handleSearch()}>
                搜索
              </Button>
            </Form.Item>
          </Form>
          <AsyncTable
            rowKey="id"
            query={formValues}
            columns={this.columns}
            service={fetch}
          />
        </Card>
      </>
    )
  }
}

export default Form.create()(Device)
