/**
 * 核心http
 * @description  request工具类 axios实例
 * @module core/request
 * @author k.
 */

import axios from 'axios'
import {
  requestInterceptor,
  requestRejectInterceptor,
  responseErrorHandler,
  responseSuccessHandler
} from './interceptor'

// 通用axios实例
const request = axios.create({
  baseURL: '/api'
})

request.interceptors.request.use(requestInterceptor, requestRejectInterceptor)
request.interceptors.response.use(responseSuccessHandler, responseErrorHandler)

// 登录， 刷新token 专用实例
const authRequest = axios.create({
  baseURL: '/api'
})

authRequest.interceptors.response.use(
  responseSuccessHandler,
  responseErrorHandler
)

export default request
