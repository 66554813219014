import React from 'react'
import { Button, Form, Icon, Input } from 'antd'
import qs from 'querystring'
import './style.css'
import { accountLogin } from './login.service'
import { authorize } from 'core/auth/auth.service'
import { setUser } from 'core/auth/token.service'
import { PageComponent } from 'components/PageLayout'

function hasErrors(fieldsError: any) {
  return Object.keys(fieldsError).some(field => fieldsError[field])
}

class Login extends PageComponent {
  async login(loginParams: any) {
    const res = (await accountLogin(loginParams)) as any
    setUser({
      username: loginParams.userCode,
      userInfo: res.data
    })
    console.log(JSON.stringify(res.data))
    // @ts-ignore
    authorize(res.data)
    this.redirectTo()
  }

  redirectTo() {
    const { search } = window.location
    let redirect = '/app/abnormal_resources'
    if (search) {
      const query = qs.parse(search)
      if (query.redirect) {
        redirect = query.redirect as string
      }
    }
    window.location.href = redirect
  }

  handleSubmit = (e: any) => {
    e.preventDefault()
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.login({ userCode: values.userCode, pwd: values.pwd })
      }
    })
  }

  render() {
    const { getFieldDecorator, getFieldsError } = this.props.form

    return (
      <div className="login-form">
        <Form onSubmit={this.handleSubmit}>
          <Form.Item>
            {getFieldDecorator('userCode', {
              rules: [{ required: true, message: '请输入用户名' }]
            })(
              <Input
                prefix={
                  <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />
                }
                placeholder="用户名"
              />
            )}
          </Form.Item>

          <Form.Item>
            {getFieldDecorator('pwd', {
              rules: [{ required: true, message: '请输入密码' }]
            })(
              <Input
                prefix={
                  <Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />
                }
                placeholder="密码"
                type="password"
              />
            )}
          </Form.Item>

          <Form.Item>
            <Button
              className="login-form__submit"
              type="primary"
              htmlType="submit"
              disabled={hasErrors(getFieldsError())}
            >
              登录
            </Button>
          </Form.Item>
        </Form>
      </div>
    )
  }
}

export default Form.create()(Login)
