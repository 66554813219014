import { map, clone } from 'lodash'

interface RouteMeta {
  title: string
  icon?: string
  permissions?: string[]
  [key: string]: any
}

export interface Route {
  path: string
  meta?: RouteMeta
  component?: any
  routes?: Route[]
  exclude?: boolean
}

export type Routes = Route[]

class RouterFactory {
  private ROUTE_PREFIX = '/app'
  routes: Routes

  constructor(routes: Routes) {
    this.routes = this.processRoutes(routes)
  }

  private withRoutePrefix(path: string) {
    return this.ROUTE_PREFIX + path
  }

  // 处理路由信息，拼装子路由路径
  processRoutes(routes: Routes) {
    return map(routes, route => {
      const clonedRoute = clone(route)
      clonedRoute.path = this.withRoutePrefix(clonedRoute.path)
      const children = clonedRoute.routes
      if (children) {
        clonedRoute.routes = map(children, child => {
          child.path = clonedRoute.path + child.path
          return child
        })
      }
      return clonedRoute
    })
  }
}

export default RouterFactory
