import React, { forwardRef, useEffect, useState } from 'react'
import { DatePicker } from 'antd'
import moment from 'moment'
import style from './index.module.css'
import { formatDate } from './utils'

type Moment = moment.Moment | undefined

interface RangeDatePickerProps {
  value?: Moment[]
  onChange?: (value: Moment) => void
  start_time?: string
  end_time?: string
}

const RangeDatePicker: React.FC<RangeDatePickerProps> = (props, ref) => {
  const { onChange, start_time, end_time } = props

  const [startValue, setStartValue] = useState()
  const [endValue, setEndValue] = useState()

  const disabledStartDate = (startValue: Moment) => {
    if (!startValue || !endValue) {
      return false
    }
    return startValue.valueOf() > endValue.valueOf()
  }

  const disabledEndDate = (endValue: Moment) => {
    if (!endValue || !startValue) {
      return false
    }
    return endValue.valueOf() <= startValue.valueOf()
  }

  const triggerOnChange = (value: Moment) => {
    onChange?.(value)
  }

  const onStartChange = (value: Moment | null, startValueString: string) => {
    const endValueString = formatDate(endValue)
    // @ts-ignore
    setStartValue(value)
    // @ts-ignore
    triggerOnChange([startValueString, endValueString])
  }

  const onEndChange = (value: Moment | null, endValueString: string) => {
    const startValueString = formatDate(startValue)
    // @ts-ignore
    setEndValue(value)
    // @ts-ignore
    triggerOnChange([startValueString, endValueString])
  }

  useEffect(() => {
    if (start_time || end_time) {
      const dateFormat = 'YYYY-MM-DD HH:mm:ss'
      if (start_time) {
        const start_time_moment = moment(start_time, dateFormat)
        setStartValue(start_time_moment)
      }
      if (end_time) {
        const end_time_moment = moment(end_time, dateFormat)
        setEndValue(end_time_moment)
      }
      // @ts-ignore
      triggerOnChange([start_time, end_time])
    }
    // eslint-disable-next-line
  }, [start_time, end_time])
  return (
    <div ref={ref}>
      <DatePicker
        disabledDate={disabledStartDate}
        format="YYYY-MM-DD HH:mm:ss"
        value={startValue}
        placeholder="请输入开始时间"
        onChange={onStartChange}
        showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
      />
      <span className={style.separator}>~</span>
      <DatePicker
        disabledDate={disabledEndDate}
        format="YYYY-MM-DD HH:mm:ss"
        value={endValue}
        placeholder="请输入结束时间"
        onChange={onEndChange}
        showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
      />
    </div>
  )
}

export default forwardRef(RangeDatePicker)
